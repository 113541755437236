import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Events = () => {
  return (
    <Layout>
      <SEO title="Events" />
      <div className="mt-48 container mx-auto px-2">
        <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-12">
          <div>
            <h1 className="md:text-8xl text-6xl font-bold break-words">
              events
            </h1>
          </div>
          <div className="lg:col-span-5 md:col-span-2 grid lg:grid-cols-3">
            <div className="p-6 border border-solid border-black">
              <h2 className="text-4xl font-bold">Kunstkörper</h2>
              <span className="text-4xl">Ausstellung</span>
              <p className="text-4xl mt-6">19.11.2022 bis 04.12.2022</p>
              <p className="text-4xl mt-6">Kunstladen Selbitz e.V.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Events
